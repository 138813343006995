import {
  I$WWrapper,
  IAppData,
  IControllerConfig,
  IWidgetControllerConfig,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';

import { GroupsWidgetProps } from '../../types/groups-list/types';
import { BaseWidgetController } from '../BaseWidgetController';
import { LogLevel, ConsoleLogger } from '../../loggers';
import { UpdateProgress } from '../../ContentEditor/UpdateProgress';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { UserController } from '../user/UserController';

export abstract class Controller<
  T extends GroupsWidgetProps
> extends BaseWidgetController<T> {
  protected controllers: BaseWidgetController<any>[];
  protected wixCodeApi: IWixAPI;
  protected appParams: IAppData;
  protected config: IControllerConfig;

  constructor(controllerContext: ControllerParams) {
    super(controllerContext);

    const { appParams, wixCodeApi, config } = this.controllerConfig;

    this.wixCodeApi = wixCodeApi;
    this.appParams = appParams;
    this.config = config;
    this.controllers = [new UserController(controllerContext)];
    // TODO: error handler controller
  }

  updateConfig($w: any, updatedConfig: any) {
    this.config = updatedConfig;
    this.controllers.map((ctrl) => ctrl.updateConfig($w, updatedConfig));
  }

  async pageReady($w?: I$WWrapper, wixAPI?: IWixAPI) {
    // Props for SSR
    await this.setInitialAppState();
    this.controllers.map((ctrl) => ctrl.pageReady($w, wixAPI));
  }

  protected async setInitialAppState() {
    const initialProps = await this.getInitialProps();
    this.setState(initialProps);
  }

  async getInitialProps() {
    const initialProps = ({
      cssBaseUrl: this.appParams.baseUrls.staticsBaseUrl,
      currentUser: this.getCurrentUser(),
      locale: this.getLocale(),
      language: this.getSiteLanguage(),
      updateProgress: UpdateProgress.UPDATING,
    } as any) as Partial<T>;

    try {
      const controllersProps = await Promise.all(
        this.controllers.map((ctrl) => ctrl.getInitialProps()),
      );
      const flattenProps = controllersProps.reduce(
        (acc, props) => ({ ...acc, ...props }),
        {},
      );
      Object.assign(initialProps, flattenProps);
    } catch (e) {
      console.error('Get Initial Controllers Props: FAIL');
    }
    return initialProps;
  }

  exports() {
    return {};
  }

  protected setLogger(controllerConfig: IWidgetControllerConfig) {
    ConsoleLogger.mode = this.getLogLevel(controllerConfig);
  }

  private getLogLevel(controllerConfig: IWidgetControllerConfig) {
    let logLevel = LogLevel.NONE;
    try {
      if (controllerConfig.wixCodeApi.location.query.debug === 'true') {
        logLevel = LogLevel.LOG;
      }
      if (controllerConfig.wixCodeApi.location.query.trace === 'true') {
        logLevel = LogLevel.TRACE;
      }
    } catch (e) {}
    return logLevel;
  }

  getTranslationsUrl(): string {
    const baseUrl = this.getBaseUrls().staticsBaseUrl;
    const language = this.getSiteLanguage();
    // TODO: fix this
    return `${baseUrl}assets/locales/messages_${language}.json`;
  }
}
